@import "../../assets/styles/variables";

.header {
    padding-top: 14px;
    .firstLine {

        height: 43px;
        .button {
            position: absolute;
            left: 33px;
            top: 14px;
        }
        .backButton {
            margin-left: 26px;
        }
        .name {
            text-align: center;
            width: 100%;
            color: $text-color;
            font-size: $tablet-big-font-size;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
            letter-spacing: -0.75px;
        }
        .settingsButton {
            position: absolute;
            top: 14px;
            right: 33px;
        }
    }
    .secondLine {
        margin-top: 9px;
        .logo {
            //width: 275px;
            height: 70px;
        }
    }
}



@media screen and (max-width: $phone-size-max) {
    .header {
        padding-top: 14px;
        .firstLine {
            height: 46px;
            .button {
                left: 14px;
                top: 14px;
            }
            .name {
                font-size: $phone-big-font-size + 4px;
                line-height: 38px;
            }
            .settingsButton {
                top: 14px;
                right: 14px;
            }
        }
        .secondLine {
            margin-top: 9px;
            .logo {
                //width: 160px;
                height: 41px;
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .header {
        .firstLine {
            .name {
                font-size: $phone-big-font-size;
                line-height: normal;
            }
        }
        .secondLine {
            .logo {
            }
        }
    }
}