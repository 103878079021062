@import "../../../assets/styles/variables";

.headersScreen {
    .header {
        margin-top: 60px;
        color: #ffffff;
        font-size: $tablet-extra-big-font-size;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.9px;
        line-height: normal;
    }
    .subheader {
        margin-top: 12px;
        color: #000000;
        font-size: $tablet-small-font-size - 2px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.45px;
        line-height: normal;
    }
    .settingsList {
        padding-bottom: 92px;

        .item {
            display: inline-block;
            width: 47%;
            margin-top: 25px;
            vertical-align: top;

            &:nth-child(2n) {
                margin-left: 6%;
            }

            .label {
                color: #000000;
                font-size: $tablet-big-font-size;
                font-weight: 400;
                text-align: left;
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: normal;

                .heartIcon {
                    color: #fd0202;
                }
            }

            .imageDescription {
                color: #ffffff;
                font-weight: 400;
                text-align: left;
                font-style: normal;
                line-height: $tablet-small-font-size + 2px;
                font-size: 22px;
                letter-spacing: -0.55px;
                margin-top: 18px;
            }

            .imageUploader {
                margin-top: 9px;
                > div {
                    background-color: transparent;
                    border-radius: 10px;
                    border: 3px solid #000000;
                    width: 100%;
                    height: 211px;
                }
            }
            .input {
                margin-top: 9px;
                height: 60px;
                font-size: $tablet-regular-font-size;
                line-height: 60px;
            }
            .sublabel {
                color: #000000;
                font-size: $tablet-regular-font-size;
                font-weight: 300;
                font-style: normal;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: -0.6px;
                line-height: normal;
                margin-top: 5px;
            }

        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .headersScreen {
        .header {
            margin-top: 40px;
            font-size: $phone-extra-big-font-size;
        }
        .subheader {
            margin-top: 12px;
            font-size: $phone-very-small-font-size;
        }
        .settingsList {
            padding-bottom: 92px;

            .item {
                width: 47%;

                margin-top: 20px;

                .label {
                    font-size: $phone-big-font-size;
                }

                .imageDescription {
                    line-height: $phone-very-small-font-size;
                    font-size: $phone-very-small-font-size;
                    margin-top: 18px;
                }

                .imageUploader {
                    margin-top: 9px;
                    > div {
                        width: 100%;
                        height: 211px;
                    }
                }
                .input {
                    margin-top: 7px;
                    height: 44px;
                    font-size: $phone-regular-font-size;
                    line-height: 44px;
                }
                .sublabel {
                    font-size: $phone-regular-font-size;
                    margin-top: 5px;
                }

            }
        }
    }
}

@media screen and (max-width: 510px) {
    .headersScreen {
        .settingsList {
            .item {
                width: 100%;
                &:nth-child(2n) {
                    margin-left: 0;
                }
            }
        }
    }
}