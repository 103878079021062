@import "../assets/styles/variables";

.welcomeScreen {
    padding-top: 25px;

    .welcomeText {
        margin-top: 98px;
        margin-bottom: 67px;
        min-height: 115px;
    }
    .welcomeButtons {
         text-align: center;
         max-width: 440px;
        margin-left: auto;
        margin-right: auto;

        .welcomeButton1 {
            float: left;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
        .welcomeButton2 {
            float: right;
            margin-left: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
     }
}

@media screen and (max-width: $phone-size-max) {
    .welcomeScreen {
        padding-top: 25px;

        .welcomeText {
            margin-top: 60px;
            margin-bottom: 48px;
            min-height: 85px;
        }
    }
}