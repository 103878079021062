@import "../../assets/styles/variables";

.installResultScreen {
    padding-bottom: 100px;

    .logo {
        margin-top: 160px;
    }

    .row1 {
        margin-top: 54px;
        color: $text-color;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.75px;
        line-height: normal;
    }

    .codeInput {
        margin-top: 35px;
    }

    .codeButton {
        margin-top: 28px;
    }

    .row2 {
        margin-top: 60px;
        color: $text-color;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.6px;
        line-height: normal;
    }

    .subscriptionsButton {
        margin-top: 21px;
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }

    .row3 {
        margin-top: 58px;
        color: $text-color;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.6px;
        line-height: normal;
    }

    .booksButton {
        margin-top: 21px;
    }

    .logoutBlock {
        margin-top: 79px;
    }

    .limitRow {
        margin-top: 85px;
        padding-bottom: 42px;
        color: $text-color;
        font-size: 30px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: -0.75px;
    }

    .installRow1 {
        margin-top: 160px;
        color: $text-color;
        font-size: 42px;
        font-weight: 200;
        text-align: center;
        font-style: normal;
        letter-spacing: -1.05px;
        line-height: normal;
    }

    .installText {
        margin-top: 39px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-touch-callout:none;
        img {
            width: 40px;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-user-drag: none;
            -webkit-touch-callout:none;
        }
    }

    .installIcon {
        margin-top: 39px;
        text-align: center;

        img {
            width: 150px;
        }
    }

    .installButton {
        margin-top: 28px;
    }
}

@media screen and (max-width: $phone-size-max) {
    .installResultScreen {
        padding-bottom: 50px;

        .logo {
            margin-top: 50px;
        }

        .row1 {
            margin-top: 40px;
            font-size: 20px;
        }

        .codeInput {
            margin-top: 30px;
        }

        .codeButton {
            margin-top: 20px;
        }

        .row2 {
            margin-top: 40px;
            font-size: 20px;
        }

        .subscriptionsButton {
            margin-top: 15px;
        }

        .row3 {
            margin-top: 40px;
            font-size: 20px;
        }

        .booksButton {
            margin-top: 15px;
        }

        .logoutBlock {
            margin-top: 50px;
        }

        .limitRow {
            margin-top: 50px;
            padding-bottom: 30px;
            font-size: 25px;
        }

        .installRow1 {
            margin-top: 100px;
            font-size: 30px;
        }

        .installIcon {
            margin-top: 28px;
            text-align: center;

            img {
                width: 150px;
            }
        }
    }
}