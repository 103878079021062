@import "../assets/styles/variables";

.inputNumber {
  font-size: 0;
  text-align: center;
  max-width: 330px;

  .increase, .decrease {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 10px;
    background-color: #666666;
    border: none;
    font-size: 48px;
    font-weight: 400;

    display: inline-block;

    &:hover {
      background-color: #cccccc;
      color: #333333;
    }
  }

  .increase {
    float: right;
  }
  .decrease {
    float: left;
  }

  .input {
    width: calc(100% - 156px);
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}

@media screen and (max-width: $phone-size-max) {
  .inputNumber {
    max-width: 330px;

    .increase, .decrease {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 30px;
    }

    .input {
      width: calc(100% - 100px);
      height: 30px;
      line-height: 30px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: $small-size-max) {

}
