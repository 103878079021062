@import "../assets/styles/variables";

.toggleButton {
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    background-color: #cccccc;
    padding: 0px 8px;
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.45px;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.checked {
        background-color: #666666;
        color: #ffffff;
    }

    &.disabled {

    }
}

@media screen and (max-width: $phone-size-max) {
    .toggleButton {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }
}

@media screen and (max-width: $small-size-max) {

}
