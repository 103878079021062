@import "../../../assets/styles/variables";

.section {
  border: 3px solid #000000;
  width: 32%;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 11px;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  position: relative;

  margin-right: 2%;
  &:nth-child(3n) {
    margin-right: 0;
  }

  .name {
    background-color: #cccccc;
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: -0.6px;
    line-height: normal;

    padding-top: 15px;
    padding-bottom: 11px;
  }

  .description {
    background-color: #666666;
    padding-bottom: 22px;
    overflow: hidden;
    span {
      color: #ffffff;
      font-size: 19px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      font-style: normal;
      letter-spacing: -0.55px;
      display: block;
      padding: 16px 10px;
    }

    .icon {

    }
  }

  &.disabled {
    &::before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.7;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after {
      content: "\f023";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      color: #fff;
      font-size: 25px;
      display: block;
      position: absolute;
      right: 13px;
      bottom: 13px;
    }
  }
}



@media screen and (max-width: $phone-size-max) {
  .section {

    margin-bottom: 11px;

    .name {
      font-size: 18px;

      padding-top: 15px;
      padding-bottom: 11px;
    }

    .description {
      padding-bottom: 22px;
      span {
        font-size: 14px;
        padding: 16px 10px;
      }

      .icon {

      }
    }

  }
}

@media screen and (max-width: 599px) {
  .section {
    width: 48%;
    margin-right: 2%;

    &:nth-child(3n) {
      margin-right: 2%;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $small-size-max) {
  .section {
    width: 100%;
    margin-right:0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}