@import "../../../assets/styles/variables";

.book {
    padding-top: 25px;

    .bookCodeText {
        margin-top: 98px;
        margin-bottom: 67px;
    }
    .codeInput {

    }

    .errorText {
        margin-top: 20px;
        margin-bottom: 50px;
        height: 24px;
        color: #fff;
        font-size: $tablet-very-small-font-size;
    }


    .bookCodeButtons {
        text-align: center;
        max-width: 440px;
        margin-left: auto;
        margin-right: auto;

        .bookCodeButton1 {
            float: left;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
        .bookCodeButton2 {
            float: right;
            margin-left: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }

    .bookCodeBottom {
        margin-top: 321px;
        color: #ffffff;
        font-size: $tablet-big-font-size;
        font-weight: 300;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.75px;
        line-height: normal;
    }
    padding-bottom: 20px;
}

@media screen and (max-width: $phone-size-max) {
    .book {

        .bookCodeText {
            margin-top: 60px;
            margin-bottom: 30px;

            h3 {
                line-height: 30px;
            }
        }

        .codeInput {

        }

        .errorText {
            margin-top: 20px;
            margin-bottom: 20px;
            height: 18px;
            color: #fff;
            font-size: $tablet-very-small-font-size;
        }

        .bookCodeBottom {
            margin-top: 160px;
            font-size: $phone-big-font-size;
        }
    }
}