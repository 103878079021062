@import "../../../assets/styles/variables";

.textsOverlay {
    position: fixed;
    left: 0;
    top: 0;
    padding: 23px;
    height: 100%;
    width: 100%;
    //background-color: rgba(0, 0, 0, 0.85);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 10;

    .overlayWrapper {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        position: relative;

        .box {
            margin-top: 30%;
            border-radius: 10px;
            border: 3px solid #ffffff;
            background-color: rgba(0,0,0,0.85);
            padding: 35px 42px;
            position: relative;
            width: 100%;
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .header {
                .name {
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: left;
                    letter-spacing: -0.6px;
                    margin-right: 60px;
                }
                .close {
                    position: absolute;
                    top: 20px;
                    right: 27px;
                }
                margin-bottom: 37px;
            }

            .input {
                width: 100%;
                height: 60px;
                line-height: 60px;
                border-radius: 10px;
                border: none;
                background-color: #ffffff;
                color: #666666;
                font-size: $tablet-input-font-size;
                font-weight: 300;
                line-height: normal;
                text-align: left;
                font-style: normal;
                letter-spacing: -0.65px;
                padding: 18px 13px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-top: 8px;
            }

            .buttons {
                margin-top: 51px;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .textsOverlay {
        position: fixed;
        left: 0;
        top: 0;
        padding: 23px;
        height: 100%;
        width: 100%;
        //background-color: rgba(0, 0, 0, 0.85);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow-y: scroll;
        z-index: 10;

        .overlayWrapper {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 100%;
            position: relative;

            .box {
                margin-top: 30%;
                border-radius: 10px;
                border: 3px solid #ffffff;
                background-color: rgba(0,0,0,0.85);
                padding: 18px 20px;
                position: relative;
                width: 100%;
                max-width: 680px;
                margin-left: auto;
                margin-right: auto;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .header {
                    .name {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 400;
                        font-style: normal;
                        line-height: normal;
                        text-align: left;
                        letter-spacing: -0.6px;
                        margin-right: 60px;
                    }
                }

                .input {
                    height: 45px;
                    width: 100%;
                    border-radius: 10px;
                    border: none;
                    background-color: #ffffff;
                    color: #666666;
                    font-size: $phone-input-font-size;
                    font-weight: 300;
                    line-height: normal;
                    text-align: left;
                    font-style: normal;
                    letter-spacing: -0.65px;
                    padding: 18px 13px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                .buttons {
                    text-align: center;
                }
            }
        }
    }
}