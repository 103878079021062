@import "../../../assets/styles/variables";

.sections {
  font-size: 0;
  text-align: left;

  .subscriptionsHeader {
    height: 56px;
    padding-top: 14px;
    box-sizing: border-box;
  }
  .subscriptionsDescription {
    height: 111px;
  }
}


@media screen and (max-width: $phone-size-max) {
  .sections {
    .subscriptionsHeader {
      height: 42px;
      padding-top: 10px;
    }
  }
}

@media screen and (max-width: $small-size-max) {

}