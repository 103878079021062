@import "../../../assets/styles/variables";

.labelsScreen {
    .header {
        margin-top: 102px;
        color: #ffffff;
        font-size: $tablet-extra-big-font-size;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.9px;
        line-height: normal;
    }
    .subheader {
        margin-top: 12px;
        color: #000000;
        font-size: $tablet-small-font-size - 2px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.45px;
        line-height: normal;
    }
    .settingsList {
        padding-bottom: 92px;

        .item {
            margin-top: 30px;
            .label {
                color: #000000;
                font-size: $tablet-big-font-size;
                font-weight: 400;
                font-style: normal;
                text-align: left;
                letter-spacing: -0.75px;
                line-height: normal;
            }

            .sublabel {
                margin-top: 14px;
                color: #000000;
                font-size: $tablet-regular-font-size;
                font-weight: 300;
                text-align: left;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.6px;
                line-height: normal;
            }

            .input {
                margin-top: 9px;
                height: 60px;
                font-size: $tablet-regular-font-size;
                line-height: 60px;
            }

        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .labelsScreen {
        .header {
            margin-top: 62px;
            font-size: $phone-extra-big-font-size;
        }
        .subheader {
            margin-top: 9px;
            font-size: $phone-regular-font-size;
        }
        .settingsList {
            padding-bottom: 50px;

            .item {
                margin-top: 20px;
                .label {
                    font-size: $phone-big-font-size;
                }

                .sublabel {
                    margin-top: 10px;
                    font-size: $phone-regular-font-size;
                }

                .input {
                    margin-top: 7px;
                    height: 44px;
                    font-size: $phone-regular-font-size;
                    line-height: 44px;
                }

            }
        }
    }
}