@import "../../../assets/styles/variables";

.settingsScreen {
    .sections {
        margin-top: 100px;
    }

    .signOut {
        margin-top: 30px;
        a {
            width: 300px;
        }
    }

    .footer {
        margin-top: 119px;

        .contacts {
            color: #ffffff;
            font-size: 24px;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            font-style: normal;
            letter-spacing: -0.6px;

            span {
                display: inline-block;
            }
        }
        .bottomLine {
            text-align: center;
            margin-top: 14px;
            padding-bottom: 70px;

            .copyright {
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
                text-align: center;
                letter-spacing: -0.45px;
                display: inline-block;
                width: 50%;
            }
            .infoLink {
                float: right;
                color: #000000;
                font-size: 20px;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
                text-align: center;
                text-decoration: underline;
                letter-spacing: -0.5px;
                display: inline-block;
                width: 50%;

                & a {
                    color: #000000;
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .settingsScreen {
        .sections {
            margin-top: 70px;
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .settingsScreen {
        .sections {
            margin-top: 40px;
        }
    }
}