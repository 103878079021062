@import "../../../assets/styles/variables";

.priceRow {

  .nameInput {
    width: 63%;
    height: 60px;
    line-height: 60px;
  }

  .priceInput {
    width: 35%;
    margin-left: 2%;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    color: #666666;
    font-size: $tablet-input-font-size;
    font-weight: 300;
    text-align: left;
    font-style: normal;
    letter-spacing: -0.65px;
    padding-left: 22px;
    padding-right: 22px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (max-width: $phone-size-max) {
  .priceRow {
    .nameInput {
      height: 45px;
      line-height: 45px;
    }
    .priceInput {
      font-size: $phone-input-font-size;
      padding-left: 14px;
      padding-right: 14px;
      height: 45px;
      line-height: 45px;
    }
  }
}