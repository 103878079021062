@import "../../assets/styles/variables";

.lookScreen {

    .title {
        h3 {
            margin-top: 21px;
            margin-bottom: 42px;
        }
    }

    .topRow {
        width: 100%;
        margin-bottom: 42px;

        .search {
            width: 80%;
            display: inline-block;
        }
        .dots {
            width: 10%;
            display: inline-block;
            text-align: right;

            .dotsButton {
                width: auto;
                background-color: transparent;
                border: none;

                &:hover {
                    color: #cccccc;
                }
            }
        }
        .featured {
            width: 10%;
            display: inline-block;
            text-align: right;

            .featuredButton {
                width: auto;
                background-color: transparent;
                border: none;

                &:hover {
                    color: #cccccc;
                }
                &.enabled {
                    color: #FF0000;
                }
            }
        }
    }

    .look {
        font-size: 0;
        width: 100%;
        display: block;
        text-align: left;
        margin-top: 30px;
        //padding-left: 23px;
        //padding-right: 23px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .item {
            width: 19%;
            background-color: #000;
            display: inline-block;
            margin-bottom: 11px;
            cursor: pointer;
            position: relative;

            .image {
                //1582/2048
                width: 100%;
                padding-bottom: 129%;
                display: inline-block;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;

                img {
                    position: absolute;
                    width: 100%;
                }
            }

            .name {
                color: #ffffff;
                font-size: $tablet-tiny-font-size;
                font-weight: 400;
                text-align: left;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.35px;
                line-height: normal;
                padding: 7px 10px;

                .lock {
                    color: #ffffff;
                    float: right;
                }
            }

            &.disabled {
                .image:after {
                    content: '\A';
                    position: absolute;
                    width: 100%; height:100%;
                    top:0; left:0;
                    background:rgba(0,0,0,0.7);
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                .name {
                    color: #999999;
                }
            }

            .featuredIcon {
                font-size: 30px;
                color: #fff;
                position: absolute;
                bottom: 5px;
                right: 10px;

                &.selected {
                    color: #ff0000;
                }
            }
        }

        .divider {
            width: 1%;
            height: 1px;
            display: inline-block;
            &:nth-child(5n) {
                width: 0;
            }
        }
    }
    .overlay {
        position: fixed;
        left: 0;
        top: 0;
        padding: 23px;
        height: 100%;
        width: 100%;
        background-color: $bg-with-opacity;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow-y: scroll;

        .overlayWrapper {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 100%;
            position: relative;

            .header {
                position: relative;
                margin-top: 53px;
                .name {
                    color: $text-color;
                    font-size: 36px;
                    font-weight: 400;
                    text-align: left;
                    text-transform: uppercase;
                    font-style: normal;
                    letter-spacing: -0.9px;
                    line-height: normal;

                }
                .close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-right: 38px;
                }
            }

            .filters {
                margin-top: 90px;
                .name {
                    color: $text-color;
                    font-size: 36px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: -0.9px;
                }

                .select {
                    margin-top: 19px;
                    color: $text-color;
                    font-size: $tablet-big-font-size;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    letter-spacing: -0.75px;
                }

                .buttons {
                    margin-top: 20px;
                    .toggleButton {
                        height: auto;
                        font-size: $tablet-regular-font-size;
                        font-weight: 300;
                        font-style: normal;
                        text-align: center;
                        text-transform: uppercase;
                        letter-spacing: -0.6px;
                        line-height: $tablet-regular-font-size;
                        padding: 15px 0px;
                        margin: 12px 12px ;
                        width: 183px;
                    }
                }
            }
            .deselectFeatured {
                margin-top: 65px;

                .name {
                    color: $text-color;
                    font-size: $tablet-big-font-size;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    letter-spacing: -0.75px;
                    margin-bottom: 15px;
                }

                .subname {
                    color: $text-color;
                    font-size: $tablet-regular-font-size;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    letter-spacing: -0.75px;
                    margin-bottom: 30px;
                }

                .button {
                    font-size: $tablet-regular-font-size;
                    font-weight: 300;
                    font-style: normal;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: -0.6px;
                    padding: 22px 21px;

                    i {
                        font-size: 35px;
                        color: #fff;
                        margin-left: 21px;
                        vertical-align: top;
                    }
                }
                .buttonChecked {
                    i {
                        color: #ff0000;
                    }
                }
            }

            .applyButton {
                margin-top: 82px;
            }

            .openInstructions {
                margin-top: 193px;

                span {
                    color: $text-color;
                    font-size: $tablet-regular-font-size;
                    font-weight: 400;
                    font-style: normal;
                    line-height: $tablet-regular-font-size;
                    letter-spacing: -0.6px;
                }
                .infoButton {
                    margin-left: 13px;
                    width: 62px;
                    height: 40px;
                    line-height: 40px;

                    &:hover {
                        img {
                            filter: invert(100%);
                        }
                    }
                }
            }

            .instructions {
                margin-top: 118px;
                .name {
                    color: $text-color;
                    font-size: 36px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: -0.9px;
                }

                .name2 {
                    margin-top: 72px;
                    color: $text-color;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    font-size: $tablet-big-font-size;
                    letter-spacing: -0.75px;
                }

                .description {
                    margin-top: 28px;
                    color: $text-color;
                    font-size: $tablet-regular-font-size;
                    font-weight: 400;
                    text-align: center;
                    font-style: normal;
                    letter-spacing: -0.6px;
                    line-height: normal;
                    max-width: 488px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .description2 {
                    margin-top: 36px;
                    color: $text-color;
                    font-size: $tablet-regular-font-size;
                    text-align: left;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: -0.6px;
                    line-height: normal;
                    max-width: 483px;
                    margin-left: auto;
                    margin-right: auto;

                    span {
                        color: $text-color;
                        font-size: $tablet-regular-font-size;
                        text-align: left;
                        font-weight: 100;
                        font-style: normal;
                        letter-spacing: -0.6px;
                        line-height: normal;
                    }
                }

                .image {
                    margin-top: 24px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 524px;
                    width: 100%;
                }

                .toCloseButton {
                    margin-top: 117px;
                }
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .lookScreen {
        .title {
            h3 {
                margin-top: 10px;
                margin-bottom: 17px;
            }
        }

        .topRow {
            margin-bottom: 27px;

            .search {
                width: 80%;

            }
            .dots {
                width: 10%;

                .dotsButton {
                    font-size: 20px;
                }
            }
            .featured {
                width: 10%;

                .featuredButton {
                    font-size: 20px;
                }
            }
        }

        .look {
            margin-top: 15px;
            .item {
                width: 32%;
                margin-bottom: 6px;

                .name {
                    font-size: $phone-tiny-font-size;
                    padding: 7px 10px;
                }
                .featuredIcon {
                    font-size: 20px;
                    bottom: 5px;
                    right: 7px;
                }
            }

            .divider {
                width: 2%;
                height: 1px;
                display: inline-block;
                &:nth-child(5n) {
                    width: 2%;
                }
                &:nth-child(3n) {
                    width: 0;
                }
            }
        }
        .overlay {
            padding: 23px;

            .overlayWrapper {
                max-width: 800px;

                .header {
                    margin-top: 20px;
                    .name {
                        font-size: 25px;
                    }
                    .close {
                        margin-right: 0px;
                    }
                }

                .filters {
                    margin-top: 60px;
                    .name {
                        font-size: 25px;
                        font-weight: 400;
                    }

                    .select {
                        margin-top: 15px;
                        font-size: $phone-big-font-size;
                    }

                    .buttons {
                        margin-top: 15px;
                        .toggleButton {
                            font-size: $phone-regular-font-size;
                            line-height: $phone-regular-font-size;
                            padding: 10px 0px;
                            margin: 8px 8px ;
                            width: 120px;
                        }
                    }
                }
                .deselectFeatured {
                    margin-top: 45px;

                    .name {
                        font-size: $phone-big-font-size;
                        margin-bottom: 25px;
                    }
                    .subname {
                        color: $text-color;
                        font-size: $phone-regular-font-size;
                        font-weight: 400;
                        font-style: normal;
                        line-height: normal;
                        text-align: center;
                        letter-spacing: -0.75px;
                        margin-bottom: 30px;
                    }
                    .button {
                        font-size: $phone-regular-font-size;
                        padding: 10px 10px;

                        i {
                            font-size: 30px;
                            margin-left: 21px;
                        }
                    }
                }

                .applyButton {
                    margin-top: 60px;
                }

                .openInstructions {
                    margin-top: 100px;

                    span {
                        font-size: $phone-regular-font-size;
                        line-height: $phone-regular-font-size;
                    }
                    .infoButton {
                        margin-left: 13px;
                        width: 46px;
                        height: 30px;
                        line-height: 30px;
                        vertical-align: sub;
                    }
                }

                .instructions {
                    margin-top: 60px;
                    .name {
                        font-size: 25px;
                    }

                    .name2 {
                        margin-top: 40px;
                        font-size: $phone-big-font-size;
                    }

                    .description {
                        margin-top: 20px;
                        font-size: $phone-regular-font-size;
                        max-width: 488px;
                    }

                    .description2 {
                        margin-top: 25px;
                        font-size: $phone-regular-font-size;
                        max-width: 483px;
                        span {
                            font-size: $phone-regular-font-size;
                        }
                    }

                    .image {
                        margin-top: 18px;
                    }

                    .toCloseButton {
                        margin-top: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .lookScreen {
        .look {
            .item {
                width: 48%;

                .name {
                    font-size: $phone-regular-font-size;
                    padding: 7px 10px;
                }
            }

            .divider {
                width: 4%;
                &:nth-child(3n) {
                    width: 4%;
                }
                &:nth-child(5n) {
                    width: 4%;
                }
                &:nth-child(4n) {
                    width: 0;
                }
            }
        }
    }
}