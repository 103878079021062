@import "../assets/styles/variables";

.stateButtons {
    font-size: 0;
    text-align: left;

    .stateButton {
        height: 60px;
        line-height: 60px;
        border-radius: 10px;
        background-color: #cccccc;
        padding: 0px 8px;
        color: #333333;

        font-size: 24px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.6px;

        display: inline-block;
        cursor: pointer;
        width: 47%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        box-sizing: border-box;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &.checked {
            background-color: #666666;
            color: #ffffff;
        }

        img {
            vertical-align: sub;
            margin-left: 5px;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .stateButtons {
        .stateButton {
            height: 30px;
            line-height: 30px;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: $small-size-max) {

}
