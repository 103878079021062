@import "../assets/styles/variables";

.toggler {

    .label {
        cursor: pointer;
        color: #000000;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: -0.6px;
        line-height: 35px;
        display: inline-block;
        vertical-align: baseline;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .button {
        display: inline-block;
        width: 73px;
        height: 35px;
        border-radius: 18px;
        background-color: #ffffff;
        position: relative;
        cursor: pointer;
        margin-left: 9px;
        margin-right: 9px;
        vertical-align: bottom;

        .cycle {
            width: 44px;
            height: 44px;
            background-color: #666666;
            border-radius: 50%;
            position: absolute;
            top: -4px;

            &.first {
                left: 0;
            }
            &.second {
                right: 0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .toggler {
        .label {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .toggler {
        .label {
            font-size: 16px;
            line-height: 30px;
        }

        .button {
            width: 45px;
            height: 20px;
            border-radius: 18px;
            margin-left: 5px;
            margin-right: 5px;
            vertical-align: sub;

            .cycle {
                width: 25px;
                height: 25px;
                top: -2px;
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {

}
