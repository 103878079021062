@import "../../assets/styles/variables";

.booksScreen {
    .books {
        font-size: 0;
        width: 100%;
        display: block;
        text-align: left;
        margin-top: 30px;
        //padding-left: 23px;
        //padding-right: 23px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .book {
            width: 32%;
            background-color: #000;
            display: inline-block;
            margin-bottom: 11px;
            cursor: pointer;


            .image {
                //1582/2048
                width: 100%;
                padding-bottom: 129%;
                display: inline-block;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .name {
                color: #ffffff;
                font-size: $tablet-tiny-font-size;
                font-weight: 400;
                text-align: left;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.35px;
                line-height: normal;
                padding: 7px 10px;

                .lock {
                    color: #ffffff;
                    float: right;
                }
            }

            &.disabled {
                .image:after {
                    content: '\A';
                    position: absolute;
                    width: 100%; height:100%;
                    top:0; left:0;
                    background:rgba(0,0,0,0.7);
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                .name {
                    color: #999999;
                }
            }
        }

        .divider {
            width: 2%;
            height: 1px;
            display: inline-block;
            &:nth-child(3n) {
                width: 0;
            }
        }
    }

    .codeOverlay {
        position: fixed;
        left: 0;
        top: 0;
        padding: 23px;
        height: 100vh;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .box {
            margin-top: 30%;
            border-radius: 10px;
            border: 3px solid #ffffff;
            background-color: rgba(0,0,0,0.85);
            padding: 35px 42px;
            position: relative;
            width: 100%;
            max-width: 680px;
            margin-left: auto;
            margin-right: auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .close {
                position: absolute;
                top: 20px;
                right: 27px;
            }

            .text {
                color: #ffffff;
                font-size: $tablet-regular-font-size;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
                text-align: left;
                letter-spacing: -0.6px;
                opacity: 1;
            }

            .codeInput {
                margin-top: 45px;
                margin-bottom: 15px;
            }

            .errorText {
                height: 30px;
                margin-bottom: 7px;
                color: #ffffff;
            }

            .buttons {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .booksScreen {
        .books {
            margin-top: 15px;
            .book {
                width: 48%;
                margin-bottom: 11px;

                .name {
                    font-size: $phone-tiny-font-size;
                    padding: 7px 10px;
                }
            }

            .divider {
                width: 4%;
                height: 1px;
                display: inline-block;
                &:nth-child(3n) {
                    width: 4%;
                }
                &:nth-child(4n) {
                    width: 0;
                }
            }
        }

        .codeOverlay {
            padding: 23px;

            .box {
                margin-top: 30%;
                padding: 25px 25px;
                max-width: 680px;

                .close {
                    top: 20px;
                    right: 27px;
                }

                .text {
                    font-size: $phone-regular-font-size;
                }

                .codeInput {
                    margin-top: 35px;
                    margin-bottom: 15px;
                }

                .errorText {
                    height: 22px;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .booksScreen {
        .books {
            .book {
                .name {
                    font-size: $phone-tiny-font-size - 1px;
                    padding: 7px 6px;
                }
            }

        }
    }
}

@media screen and (max-width: 300px) {
    .booksScreen {
        .books {
            .book {
                .name {
                    font-size: $phone-tiny-font-size - 2px;
                    padding: 7px 4px;
                }
            }

        }
    }
}