@import "../../../assets/styles/variables";

.lookOverlay {
    position: fixed;
    left: 0;
    top: 0;
    padding: 23px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;

    .overlayWrapper {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        position: relative;
    }
}

@media screen and (max-width: $phone-size-max) {
    .lookOverlay {

    }
}