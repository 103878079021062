@import "../assets/styles/variables";

.inputText {
    width: 100%;
    height: 80px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    color: #666666;
    font-size: $tablet-input-font-size;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    font-style: normal;
    letter-spacing: -0.65px;
    padding-left: 22px;
    padding-right: 22px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:disabled {
        background-color: #000000;
        opacity: 0.6;
        color: #5f5f5e;
     }
    &.error {
        background-color: #ffb1b1;
    }
}

@media screen and (max-width: $phone-size-max) {
    .inputText {
        width: 100%;
        height: $phone-button-height;
        display: inline-block;
        border-radius: 10px;
        border: none;
        background-color: #ffffff;
        color: #666666;
        font-size: $phone-input-font-size;
        font-weight: 300;
        line-height: normal;
        text-align: left;
        font-style: normal;
        letter-spacing: -0.65px;
        padding-left: 14px;
        padding-right: 14px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}