@import "../../assets/styles/variables";

.bookScreen {

    .title {
        h3 {
            margin-top: 21px;
            margin-bottom: 42px;
        }
    }

    .book {
        font-size: 0;
        width: 100%;
        display: block;
        text-align: left;
        margin-top: 30px;
        //padding-left: 23px;
        //padding-right: 23px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .page {
            width: 19%;
            background-color: #000;
            display: inline-block;
            margin-bottom: 11px;
            cursor: pointer;

            .image {
                //1582/2048
                width: 100%;
                padding-bottom: 129%;
                display: inline-block;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .name {
                color: #ffffff;
                font-size: $tablet-tiny-font-size;
                font-weight: 400;
                text-align: left;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.35px;
                line-height: normal;
                padding: 7px 10px;

                .lock {
                    color: #ffffff;
                    float: right;
                }
            }

            &.disabled {
                .image:after {
                    content: '\A';
                    position: absolute;
                    width: 100%; height:100%;
                    top:0; left:0;
                    background:rgba(0,0,0,0.7);
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                .name {
                    color: #999999;
                }
            }
        }

        .divider {
            width: 1%;
            height: 1px;
            display: inline-block;
            &:nth-child(5n) {
                width: 0;
            }
        }
    }

    .bottomText {
        margin-top: 30px;
        padding-bottom: 40px;
        .row1 {
            font-size: $tablet-big-font-size;
            font-style: normal;
            letter-spacing: -0.6px;
            line-height: normal;
            color: $text-color;
            margin-bottom: 10px;
        }
        .row2 {
            font-size: $tablet-small-font-size;
            font-style: normal;
            letter-spacing: -0.6px;
            line-height: normal;
            color: $text-color;
        }
        .row3 {
            font-size: $tablet-small-font-size;
            font-style: normal;
            letter-spacing: -0.6px;
            line-height: normal;
            color: $text-color;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .bookScreen {
        .title {
            h3 {
                margin-top: 10px;
                margin-bottom: 17px;
            }
        }
        .book {
            margin-top: 15px;
            .page {
                width: 32%;
                margin-bottom: 6px;

                .name {
                    font-size: $phone-tiny-font-size;
                    padding: 7px 10px;
                }
            }

            .divider {
                width: 2%;
                height: 1px;
                display: inline-block;
                &:nth-child(5n) {
                    width: 2%;
                }
                &:nth-child(3n) {
                    width: 0;
                }
            }
        }
        .bottomText {
            margin-top: 25px;
            padding-bottom: 30px;
            .row1 {
                font-size: $phone-big-font-size;
            }
            .row2 {
                font-size: $phone-small-font-size;
            }
            .row3 {
                font-size: $phone-small-font-size;
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .bookScreen {
        .book {
            .page {
                width: 48%;

                .name {
                    font-size: $phone-regular-font-size;
                    padding: 7px 10px;
                }
            }

            .divider {
                width: 4%;
                &:nth-child(3n) {
                    width: 4%;
                }
                &:nth-child(5n) {
                    width: 4%;
                }
                &:nth-child(4n) {
                    width: 0;
                }
            }
        }
    }
}