// Colors

$bg-color: var(--bg-color);
$bg-with-opacity: var(--bg-with-opacity);
$text-color: var(--text-color);
$border-color: var(--border-color);

$invert: unquote("invert(var(--invert))");

$bg-filter-img: var(--bg-filter-img);

//Screen sizes
$tablet-size-min: 700px;
$phone-size-max: 699px;
$small-size-max: 360px;

//Tablet variables
$tablet-tiny-font-size: 14px;
$tablet-very-small-font-size: 16px;
$tablet-small-font-size: 20px;
$tablet-regular-font-size: 24px;
$tablet-big-font-size: 30px;
$tablet-extra-big-font-size: 36px;
$tablet-input-font-size: 26px;

//Phone variables
$phone-tiny-font-size: 11px;
$phone-very-small-font-size: 13px;
$phone-small-font-size: 11px;
$phone-regular-font-size: 14px;
$phone-big-font-size: 20px;
$phone-extra-big-font-size: 26px;
$phone-input-font-size: 20px;

$phone-button-height: 44px;