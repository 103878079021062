@import "../../../assets/styles/variables";

.iconSelector {
  width: 100%;
  height: 117px;

  border-radius: 10px;
  border: 3px solid #000000;
  background-color: #999999;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  box-sizing: border-box;

  .button {

    position: absolute;
    width: 45px;
    height: 45px;
    line-height: 43px;
    font-size: 38px;
    font-weight: 400;
    bottom: 2px;
    right: 2px;
    border: none;

    &.remove {
      font-size: 34px;
    }

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }



  .iconsOverlay {
    position: fixed;
    left: 0;
    top: 0;
    padding: 23px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;

    .overlayWrapper {
      max-width: 685px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100%;
      position: relative;

      .iconsTable {
        border-radius: 10px;
        border: 3px solid #ffffff;
        background-color: #999999;
        width: 100%;
        padding-bottom: 20px;

        .header {
          position: relative;
          .name {
            color: #ffffff;
            font-size: 36px;
            font-weight: 400;
            font-style: normal;
            line-height: normal;
            text-align: left;
            letter-spacing: -0.9px;

            padding-left: 43px;
            padding-top: 32px;
          }
          .close {
            position: absolute;
            top: 21px;
            right: 30px;

            a {
              width: 51px;
              height: 51px;
              line-height: 51px;
              background: transparent;

              &:hover {
                color: #fff;
              }
            }
          }
        }

        .list {
          margin-top: 59px;
          .iconWrapper {
            display: inline-block;
            width: 95px;
            height: 95px;
            text-align: center;
            line-height: 95px;
            padding: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $phone-size-max) {
  .iconSelector {
    width: 100%;
    height: 112px;

    border: 3px solid #000000;

    .button {
      width: 30px;
      height: 30px;
      line-height: 28px;
      font-size: 26px;
      bottom: 2px;
      right: 2px;

      &.remove {
        font-size: 34px;
      }

    }


    .iconsOverlay {
      padding: 15px;

      .overlayWrapper {
        max-width: 685px;

        .iconsTable {
          border: 3px solid #ffffff;
          width: 100%;
          padding-bottom: 20px;

          .header {
            position: relative;
            .name {
              font-size: $phone-extra-big-font-size;

              padding-left: 15px;
              padding-top: 22px;
              padding-right: 60px;
            }
            .close {
              top: 15px;
              right: 15px;

              a {
                width: 45px;
                height: 45px;
                line-height: 45px;
              }
            }
          }

          .list {
            margin-top: 30px;
            .iconWrapper {
              width: 95px;
              height: 95px;
              line-height: 95px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small-size-max) {
  .iconSelector {
    height: 104px;
  }
}