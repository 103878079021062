@import "../../assets/styles/variables";

.tags {
    text-align: left;

    .tag {
        margin-left: 4px;
        margin-right: 4px;
    }

    .arrow {
        font-size: 40px;

        &.right i{
            margin-left: 6px;
        }
        &.left i{
            margin-right: 6px;
        }
    }

    .wrapper {
        margin-left: 18px;
        margin-right: 18px;
        width: 100%;
    }

    .itemWrapper {
        outline: none;
    }
}

@media screen and (max-width: $phone-size-max) {
    .tags {
        .arrow {
            font-size: 25px;
            line-height: 30px;
            height: 30px;
            width: 30px;

            &.right i {
                margin-left: 5px;
            }

            &.left i {
                margin-right: 5px;
            }
        }
        .wrapper {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .tags {
        .arrow {
            font-size: 17px;

            &.right i {
                margin-left: 2px;
            }

            &.left i {
                margin-right: 2px;
            }
        }
    }
}