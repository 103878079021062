@import "../../../assets/styles/variables";

.serviceBlock {
  border-radius: 10px;
  background-color: #666666;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .header {
    font-size: 0;

    .icon {
      width: 25%;
      display: inline-block;
      vertical-align: top;
    }

    .name {
      width: 73%;
      margin-left: 2%;
      display: inline-block;
      vertical-align: top;
      .tip {
        margin-top: 17px;
        color: #000000;
        font-size: 22px;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        letter-spacing: -0.6px;
        line-height: normal;
      }

      .input {
        height: 60px;
        line-height: 60px;
        margin-top: 11px;
      }
    }
  }

  .prices {
    .priceRow {
      margin-top: 11px;
    }
  }

  .bottom {

    margin-top: 11px;

    &::after {
      content: ' ';
      display: block;
      clear: both;
    }
    .newRow, .removeRow {
      &Tip {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: -0.3px;
      }

      &Button {
        margin-top: 9px;
        width: 60px;
        height: 60px;
        border: none;
        background-color: #999999;
        color: #ffffff;
        font-size: 58px;
        font-weight: 300;
        line-height: 60px;
      }
    }

    .removeRow {
      text-align: left;
      float: left;
    }

    .newRow {
      text-align: right;
      float: right;
    }

  }
}

@media screen and (max-width: $phone-size-max) {
  .serviceBlock {
    padding: 10px;

    .header {

      .icon {
        width: 35%;
      }

      .name {
        width: 63%;
        margin-left: 2%;
        .tip {
          margin-top: 13px;
          font-size: 17px;
          font-weight: 400;
        }

        .input {
          height: 45px;
          line-height: 45px;
          margin-top: 8px;
        }
      }
    }

    .prices {
      .priceRow {
        margin-top: 8px;
      }
    }

    .bottom {

      margin-top: 7px;

      .newRow, .removeRow {
        &Tip {
          font-size: 12px;
        }

        &Button {
          margin-top: 5px;
          width: 45px;
          height: 45px;
          font-size: 45px;
          line-height: 45px;
        }
      }

    }
  }
}

@media screen and (max-width: $small-size-max) {
  .serviceBlock {
    .header {
      .name {
        .tip {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}