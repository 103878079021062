@import "../../assets/styles/variables";

.pswp__ui {
  height: 100%;
}

.pswp__bg {
  background: $bg-color;
}

.pswp__top-bar {
  height: 130px;
  background-color: $bg-with-opacity;
  text-align: left;
}

.pswp__bottom-bar {
  height: 130px;
  width: 100%;
  background-color: $bg-with-opacity;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp__summary {
  width: 485px;
  height: 100%;
  background-color: $bg-with-opacity;
  text-align: left;
  padding-top: 165px;
  padding-bottom: 130px;
  padding-left: 60px;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);

  a {
    color: $text-color;
    font-size: $tablet-regular-font-size;
    font-weight: 300;
    text-align: left;
    font-style: normal;
    letter-spacing: -0.6px;
    line-height: 54px;
    text-decoration: none;
  }
}

.pswp__ui--hidden, .pswp__ui--idle  {
  .pswp__bottom-bar, .pswp__summary {
    opacity: 0;
  }
}


@media screen and (max-width: $phone-size-max), screen and (orientation:landscape) and (max-height: $phone-size-max) {
  .pswp__top-bar {
    height: 65px;
  }

  .pswp__bottom-bar {
    height: 65px;
  }

  .pswp__summary {
    width: 240px;
    height: 100%;
    background-color: $bg-with-opacity;
    text-align: left;
    padding-top: 75px;
    padding-bottom: 65px;
    padding-left: 30px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    will-change: opacity;
    transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);

    a {
      color: $text-color;
      font-size: $phone-regular-font-size;
      font-weight: 300;
      text-align: left;
      font-style: normal;
      letter-spacing: -0.6px;
      line-height: 30px;
      text-decoration: none;
    }
  }
}