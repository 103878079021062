@import "../assets/styles/variables";

.textBlock {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: $text-color;
    font-size: $tablet-regular-font-size;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
}

.textBlock h3 {
    font-size: $tablet-big-font-size;
    font-style: normal;
    letter-spacing: -0.75px;
    font-weight: 300;
    margin: 32px;
}

.textBlock p {
    font-style: normal;
    letter-spacing: -0.6px;
    line-height: 24px;
    margin: 32px;
}

@media screen and (max-width: $phone-size-max) {
    .textBlock {
        font-size: $phone-regular-font-size;
    }

    .textBlock h3 {
        font-size: $phone-big-font-size;
        margin: 10px;
    }

    .textBlock p {
        line-height: 24px;
        margin: 10px;
    }
}