@import "../../../assets/styles/variables";

.appScreen {
    .header {
        margin-top: 95px;
        color: #ffffff;
        font-size: $tablet-extra-big-font-size;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.9px;
        line-height: normal;
    }
    .settingsList {
        margin-top: 32px;
        padding-bottom: 92px;
        .leftColumn, .rightColumn {
            width: 47%;
            display: inline-block;
            vertical-align: top;
        }
        .middleColumn {
            width: 6%;
            display: inline-block;
        }

        .item {

            margin-top: 31px;

            .label {
                color: #000000;
                font-size: $tablet-big-font-size;
                font-weight: 400;
                text-align: left;
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: normal;

                .heartIcon {
                    color: #fd0202;
                }
            }

            .description {
                color: #ffffff;
                font-size: $tablet-small-font-size - 2px;
                font-weight: 400;
                text-align: left;
                font-style: normal;
                letter-spacing: -0.45px;
                line-height: 22px;
            }

            .imageDescription {
                font-size: $tablet-very-small-font-size;
                margin-top: 9px;
            }

            .sublabel {
                color: #000000;
                font-size: $tablet-regular-font-size;
                font-weight: 300;
                font-style: normal;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: -0.6px;
                line-height: normal;
                margin-top: 5px;
            }

            .buttons {
                margin-top: 5px;
            }

            .toggler {
                margin-top: 25px;
                margin-bottom: 25px;
            }

            .links {
                text-align: left;
            }

            .link1, .link2 {
                height: 60px;
                line-height: 60px;
                border-radius: 10px;
                background-color: #cccccc;
                padding: 0px 8px;
                color: #333333;

                font-size: $tablet-regular-font-size;
                font-weight: 400;
                text-align: center;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.6px;
                vertical-align: top;

                display: inline-block;
                cursor: pointer;
                width: 47%;
                margin-left: 1.5%;
                margin-right: 1.5%;
                box-sizing: border-box;
                text-decoration: none;

                &:hover {
                    background-color: #666666;
                    color: #ffffff;
                }
            }

            .link1 {
                margin-left: 0;
                font-size: $tablet-small-font-size - 1px;
            }
            .link2 {
                margin-right: 0;
            }

            .button {
                width: 100%;
                background-color: #666666;
                border-color: #666666;
                color: #fff;
                font-size: $tablet-small-font-size;
                font-weight: 400;
                font-style: normal;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: -0.5px;
                height: 60px;
                line-height: 60px;

                &:hover {
                    background-color: #cccccc;
                    border-color: #cccccc;
                    color: #000;
                }
            }
        }
    }


    .lookOverlay {
        position: fixed;
        left: 0;
        top: 0;
        padding: 23px;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.85);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow-y: scroll;

        .overlayWrapper {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 100%;
            position: relative;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .appScreen {
        .header {
            margin-top: 55px;
            font-size: $phone-extra-big-font-size;
        }
        .settingsList {
            margin-top: 18px;
            padding-bottom: 52px;
            .leftColumn, .rightColumn {
                width: 47%;
            }
            .middleColumn {
                width: 6%;
            }

            .item {
                margin-top: 20px;

                .label {
                    font-size: $phone-big-font-size;

                }

                .description {

                    font-size: $phone-very-small-font-size;
                }

                .imageDescription {
                    font-size: $phone-very-small-font-size;
                    margin-top: 9px;
                }

                .sublabel {
                    font-size: $phone-regular-font-size;
                    margin-top: 5px;
                }

                .buttons {
                    margin-top: 5px;

                    img {
                        height: 20px;
                    }
                }

                .toggler {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }


                .link1, .link2 {
                    height: 30px;
                    line-height: 30px;
                    padding: 0px 8px;

                    font-size: $phone-regular-font-size;

                    width: 47%;
                    margin-left: 1.5%;
                    margin-right: 1.5%;
                }

                .link1 {
                    margin-left: 0;
                    font-size: $phone-small-font-size - 1px;
                }
                .link2 {
                    margin-right: 0;
                }

                .button {
                    font-size: $phone-small-font-size;
                    height: 30px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media screen and (max-width: 510px) {
    .appScreen {
        .header {
            margin-top: 55px;
            font-size: $phone-extra-big-font-size;
        }
        .settingsList {
            margin-top: 18px;
            padding-bottom: 52px;
            .leftColumn, .rightColumn {
                width: 100%;
            }
            .middleColumn {
                width: 0;
            }

            .item {
                margin-top: 20px;

                .label {
                    font-size: $phone-big-font-size;

                }

                .description {

                    font-size: $phone-very-small-font-size;
                }

                .imageDescription {
                    font-size: $phone-very-small-font-size;
                    margin-top: 9px;
                }

                .sublabel {
                    font-size: $phone-regular-font-size;
                    margin-top: 5px;
                }

                .buttons {
                    margin-top: 5px;

                    img {
                        height: 20px;
                    }
                }

                .toggler {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }


                .link1, .link2 {
                    height: 30px;
                    line-height: 30px;
                    padding: 0px 8px;

                    font-size: $phone-regular-font-size;

                    width: 47%;
                    margin-left: 1.5%;
                    margin-right: 1.5%;
                }

                .link1 {
                    margin-left: 0;
                    font-size: $phone-small-font-size - 1px;
                }
                .link2 {
                    margin-right: 0;
                }

                .button {
                    font-size: $phone-small-font-size;
                    height: 30px;
                    line-height: 26px;
                }
            }
        }
    }
}