@import "../../../assets/styles/variables";

.servicesScreen {
    .header {
        margin-top: 102px;
        color: #ffffff;
        font-size: $tablet-extra-big-font-size;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.9px;
        line-height: normal;
    }
    .subheader {
        margin-top: 12px;
        color: #000000;
        font-size: $tablet-small-font-size - 2px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.45px;
        line-height: normal;
    }
    .settingsList {
        .serviceBlock {
            margin-top: 36px;
        }
        .bottom{
            margin-top: 18px;
            .newBlock, .removeBlock {
                &Tip {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    text-transform: uppercase;
                    letter-spacing: -0.3px;
                }
                &Button {
                    margin-top: 9px;
                    width: 60px;
                    height: 60px;
                    border: none;
                    background-color: #666666;
                    color: #ffffff;
                    font-size: 58px;
                    font-weight: 300;
                    line-height: 60px;
                }
            }
            .removeBlock {
                text-align: left;
                float: left;
            }
            .newBlock {
                text-align: right;
                float: right;
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .servicesScreen {
        .header {
            margin-top: 62px;
            font-size: $phone-extra-big-font-size;
        }
        .subheader {
            margin-top: 9px;
            font-size: $phone-regular-font-size;
        }
        .settingsList {
            .serviceBlock {
                margin-top: 25px;
            }
            .bottom{
                margin-top: 12px;
                .newBlock, .removeBlock {
                    &Tip {
                        font-size: 12px;
                    }
                    &Button {
                        margin-top: 5px;
                        width: 45px;
                        height: 45px;
                        font-size: 45px;
                        line-height: 45px;
                    }
                }
            }
        }
    }
}