@import "../assets/styles/variables";

.registrationScreen {
    padding-top: 25px;

    .registrationBlock {
        margin-top: 124px;

        .registrationText {
            color: #ffffff;
            font-size: $tablet-big-font-size;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            margin-bottom: 34px;

            .firstLine {
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: normal;
            }

            .secondLine {
                font-size: $tablet-regular-font-size;
                font-style: normal;
                letter-spacing: -0.6px;
                line-height: normal;
            }
        }
        .inputs {
            .row {
                margin-bottom: 14px;
                text-align: center;

                &.errorText {
                    height: 24px;
                    color: #fff;
                }
            }
        }
    }
    padding-bottom: 20px;
}

@media screen and (max-width: $phone-size-max) {
    .registrationScreen {
        .registrationBlock {
            margin-top: 60px;
            .registrationText {
                font-size: $phone-big-font-size;
                margin-bottom: 34px;
                .secondLine {
                    font-size: $phone-regular-font-size;
                }
            }
            .inputs {
                .row {
                    margin-bottom: 14px;
                    &.errorText {
                        height: 16px;
                        font-size: $phone-very-small-font-size;
                    }
                }
            }
        }
    }
}
