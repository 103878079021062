@import "../assets/styles/variables";

.loginScreen {
    padding-top: 25px;

    .loginBlock {
        margin-top: 124px;
        margin-bottom: 126px;

        .loginText {
            color: #ffffff;
            text-align: center;
            font-size: $tablet-big-font-size;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
            letter-spacing: -0.75px;
            margin-bottom: 34px;
        }
        .inputs {
            .row {
                margin-bottom: 14px;
                text-align: center;

                &.errorText {
                    height: 24px;
                    color: #fff;
                    font-size: $tablet-very-small-font-size;
                }
            }
        }
    }

    .registrationBlock {
        margin-top: 112px;
        padding-bottom: 20px;

        .registrationText {
            color: #ffffff;
            font-size: $tablet-big-font-size;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;

            .firstLine {
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: normal;
            }

            .secondLine {
                font-size: $tablet-regular-font-size;
                font-style: normal;
                letter-spacing: -0.6px;
                line-height: normal;
            }
        }

        .registrationButton {
            text-align: center;
            margin-top: 32px;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .loginScreen {
        padding-top: 25px;
        .loginBlock {
            margin-top: 60px;
            margin-bottom: 80px;
            .loginText {
                font-size: $phone-big-font-size;
                margin-bottom: 34px;
            }
            .inputs {
                .row {
                    margin-bottom: 14px;

                    &.errorText {
                        height: 16px;
                        font-size: $phone-very-small-font-size;
                    }
                }
            }
        }
        .registrationBlock {
            margin-top: 80px;
            .registrationText {
                font-size: $phone-big-font-size;
                .secondLine {
                    font-size: $phone-regular-font-size;
                }
            }
            .registrationButton {
                margin-top: 32px;
            }
        }
    }
}
