@import "assets/styles/variables";
@import "assets/fonts/fontawesome/scss/fontawesome";
@import "assets/fonts/fontawesome/scss/regular";
@import "assets/fonts/fontawesome/scss/solid";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

:root {
  --bg-color: #000;
  --bg-with-opacity: rgba(0,0,0,0.85);
  --text-color: #fff;
  --border-color: #fff;
  --invert: 0;
  --bg-filter-img: url("assets/images/filter_black.png");
}

.App {
  text-align: center;
  background: url("assets/images/bg.jpg") no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  max-width: 1112px;
  height: 100%;
  //position: fixed;
  //top: 0;
  //left: 0;
  //bottom:0;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg-filter-img;
    background-size: cover;
  }

  .wrapper {
    width: 100%;
    max-width: 800px;
    min-width: 280px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    position: relative;
    .container {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      width: 85%;
    }
  }
}