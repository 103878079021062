@import "../../assets/styles/variables";

.search {
  position: relative;

  input {
    height: 40px;
    padding-left: 47px;
    padding-right: 101px;
  }

  .loopIcon {
    display: block;
    position: absolute;
    left: 11px;
    top: 0;
    color: #666666;
    font-size: 30px;
    line-height: 40px;
  }

  .searchIcon {
    display: block;
    position: absolute;

    top: 3px;
    right: 12px;
  }

  .closeIcon {
    display: block;
    position: absolute;

    top: 3px;
    right: 57px;
  }
}

@media screen and (max-width: $phone-size-max) {
  .search {
    position: relative;

    input {
      height: 30px;
      font-size: 17px;
      padding-left: 38px;
      padding-right: 76px;
    }

    .loopIcon {
      font-size: 22px;
      line-height: 30px;
    }

    .searchIcon {
      display: block;
      position: absolute;

      top: 2px;
      right: 12px;

      img {
        height: 25px;
      }
    }

    .closeIcon {
      display: block;
      position: absolute;

      top: 2px;
      right: 45px;

      img {
        height: 25px;
      }
    }
  }
}