@import "../../assets/styles/variables";

.loginOverlay {
    position: fixed;
    left: 0;
    top: 0;
    padding: 23px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 10;

    .overlayWrapper {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        position: relative;


        .topRow {
            font-size: 0;
            padding-top: 32px;
            text-align: right;

            .close {
                width: 8%;
                display: inline-block;
                vertical-align: top;
                padding-top: 11px;

                a {
                    width: 51px;
                    height: 51px;
                    line-height: 51px;
                    color: #fff;
                    border-color: #fff;

                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .loginOverlay {
        .overlayWrapper {
            .topRow {
                .close {
                    a {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
    }
}