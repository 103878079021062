@import "../assets/styles/variables";

.logoutBlock {
    .logoutButton {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .confirmBlock {
        border-radius: 10px;
        border: 2px solid $border-color;
        background-color: $bg-color;
        padding: 30px 22px;
        box-sizing: border-box;

        .confirmText {
            display: inline-block;
            width: 50%;
            text-align: left;
            color: $text-color;
            font-size: 24px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: -0.6px;
            line-height: normal;
        }

        .confirmButtonWrapper {
            display: inline-block;
            width: 50%;
            text-align: center;

            .confirmButton {

            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .logoutBlock {

    }
}
