@import "../../assets/styles/variables";

.installRegistrationScreen {
    padding-top: 160px;
}

@media screen and (max-width: $phone-size-max) {
    .installRegistrationScreen {
        padding-top: 25px;
    }
}