@import "../../assets/styles/variables";

.startScreen {
    padding-top: 255px;

    .row1 {
        color: $text-color;
        font-size: 60px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -1.5px;
        line-height: normal;
    }

    .row2 {
        margin-top: 53px;
        margin-bottom: 62px;
    }

    .row3 {
        color: $text-color;
        font-size: 60px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: -1.5px;
    }

    .row4 {
        margin-top: 260px;
        padding-bottom: 87px;
        .goButton {
            &, &:hover {
                color: $text-color;
                height: 81px;
                width: 81px;
                background-color: transparent;
                line-height: 81px;
                font-size: 35px;
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .startScreen {
        padding-top: 170px;

        .row1 {
            font-size: 40px;
        }

        .row2 {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .row3 {
            font-size: 40px;
        }

        .row4 {
            margin-top: 170px;
            padding-bottom: 50px;
            .goButton {
                &, &:hover {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    font-size: 25px;
                }
            }
        }
    }
}