@import "../../assets/styles/variables";

.header {
  font-size: 0;
  padding-top: 32px;
  position: relative;
  //w 685
  .logo {
    width: 37%;
    display: inline-block;
    vertical-align: top;
  }
  .button {
    width: 46%;
    //width: 55%;
    display: inline-block;
    margin-left: 4%;
    margin-right: 4%;
    vertical-align: top;
    padding-top: 11px;


    a {
      width: 100%;
      overflow: hidden;
      font-size: $tablet-regular-font-size;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: -0.6px;
      line-height: 61px;
      height: 61px;

      &.submit {
        max-width: 178px;
      }
    }
  }
  .close {
    display: inline-block;
    vertical-align: top;
    padding-top: 11px;

    a {
      width: 51px;
      height: 51px;
      line-height: 51px;
      color: #fff;
      border-color: #fff;
      background: transparent;
    }
  }
}



@media screen and (max-width: $phone-size-max) {
  .header {
    padding-top: 32px;

    .logo {
      width: 30%;
    }
    .button {
      width: 43%;
      margin-left: 4%;
      margin-right: 4%;
      padding-top: 0;

      a {
        width: 100%;
        font-size: $phone-regular-font-size;
        line-height: 42px;
        height: 45px;

        &.submit {
          max-width: 178px;
        }
      }
    }
    .close {
      width: 18%;
      padding-top: 0;


      a {
        width: 41px;
        line-height: 41px;
        height: 41px;
      }
    }
  }
}


@media screen and (max-width: $small-size-max) {
  .header {
    .logo {
      margin-top: 7px;
    }
  }
}