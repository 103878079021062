@import "../../assets/styles/variables";

.pageViewer {
  background-color: $bg-color;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
  auto;
}

.closeButton {
  float: right;
  margin-top: 35px;
  margin-right: 37px;
}

.featuredButton {
  float: left;
  margin-top: 35px;
  margin-left: 60px;
  display: block;
  background: transparent;
  border: none;
  font-size: 50px !important;

  &:hover {
    background: transparent;
    color: #cccccc;
  }

  &.selected {
    color: #FF0000;
  }
}

.singleButton {
  margin-top: 34px;
  margin-left: 61px;
  display: inline-block;

  img {
    width: 42px;
    filter: $invert;
  }
}

.doubleButton {
  margin-top: 34px;
  margin-left: 26px;
  display: inline-block;

  img {
    width: 42px;
    filter: $invert;
  }
}

.rotateButton {
  margin-top: 34px;
  margin-left: 26px;
  display: inline-block;

  img {
    width: 74px;
    filter: $invert;
  }
}

.playButton {
  float: right;
  margin-top: 34px;
  margin-right: 54px;
  text-align: center;

  :global {
    i.fa-play {
      margin-left: 5px;
    }
  }
}

.resetButton {
  float: right;
  margin-top: 34px;
  margin-right: 31px;
  text-align: center;
}

.summaryButton {
  float: left;
  color: $text-color;
  font-size: $tablet-big-font-size;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: -0.75px;
  line-height: normal;
  cursor: pointer;
  margin-left: 61px;
  margin-top: 52px;
  display: block;
  text-decoration: none;
}

@media screen and (max-width: $phone-size-max), screen and (orientation:landscape) and (max-height: $phone-size-max) {
  .closeButton {
    margin-top: 12px;
    margin-right: 18px;
  }
  .singleButton {
    margin-top: 12px;
    margin-left: 25px;
    display: inline-block;

    img {
      width: 27px;
    }
  }

  .featuredButton {
    margin-top: 12px;
    margin-left: 18px;
    font-size: 39px !important;
  }

  .doubleButton {
    margin-top: 12px;
    margin-left: 16px;
    display: inline-block;

    img {
      width: 27px;
    }
  }

  .rotateButton {
    margin-top: 12px;
    margin-left: 16px;
    display: inline-block;
    img {
      width: 45px;
    }
  }
  .playButton {
    margin-top: 12px;
    margin-right: 18px;

    i.fa-play {
      margin-left: 3px;
    }
  }

  .resetButton {
    margin-top: 12px;
    margin-right: 18px;
  }

  .summaryButton {
    font-size: $phone-big-font-size;
    margin-left: 25px;
    margin-top: 25px;
  }
}

@media screen and (max-width: $small-size-max) {
  .closeButton {
    margin-top: 16px;
    margin-right: 18px;
  }
  .singleButton {
    margin-top: 16px;
    margin-left: 25px;
    display: inline-block;

    img {
      width: 22px;
    }
  }

  .featuredButton {
    margin-top: 16px;
    margin-left: 18px;
    font-size: 32px !important;
  }

  .doubleButton {
    margin-top: 16px;
    margin-left: 16px;
    display: inline-block;

    img {
      width: 22px;
    }
  }

  .rotateButton {
    margin-top: 16px;
    margin-left: 16px;
    display: inline-block;
    img {
      width: 37px;
    }
  }
  .playButton {
    margin-top: 16px;
    margin-right: 18px;

    i.fa-play {
      margin-left: 3px;
    }
  }
  .resetButton {
    margin-top: 16px;
    margin-right: 18px;
  }
  .summaryButton {
    font-size: $phone-big-font-size;
    margin-left: 25px;
    margin-top: 25px;
  }
}