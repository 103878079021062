@import "../../assets/styles/variables";

.menuScreen {
   .headerText {
       h3 {
           margin-top: 19px;
           margin-bottom: 114px;
       }
   }

    .table {
        width: 100%;

        .row {
            width: 100%;
            clear: both;
            .item {
                display: table;
                float: left;
                width: 49%;
                text-align: center;
                padding: 28px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                filter: $invert;

                .text {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    height: 225px;
                    text-decoration: none;

                    .big {
                        font-style: normal;
                        line-height: normal;
                        color: #fefefe;
                        font-size: 60px;
                        font-weight: 300;
                        letter-spacing: -1.5px;
                    }

                    .small {
                        color: #fefefe;
                        font-size: 40px;
                        text-align: center;
                        font-weight: 500;
                        font-style: normal;
                        letter-spacing: -1px;
                        line-height: normal;
                    }

                    .grey {
                        color: #999999;
                    }
                }
                .disabled {
                    .small, .big {
                        color: #999999;
                        cursor: default;
                    }
                }

                &Border {
                    display: table;
                    float: left;
                    width: 49%;
                    text-align: center;
                    padding: 0px 28px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    .horizontalBorder {
                        height: 3px;
                        width: 100%;
                        background-color: $border-color;
                        float: left;
                    }
                }
            }

            .verticalBorder {
                height: 225px;
                width: 3px;
                background-color: $border-color;
                float: left;
                margin-top: 28px;
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .menuScreen {

        .table {
            .row {
                .item {

                    .text {

                        .big {
                            font-size: 55px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .menuScreen {
        .headerText {
            h3 {
                margin-top: 19px;
                margin-bottom: 40px;
            }
        }

        .table {
            .row {
                .item {
                    padding: 25px;

                    .text {
                        height: 110px;

                        .big {
                            font-size: 26px;
                        }

                        .small {
                            font-size: 19px;
                        }

                    }

                    &Border {
                        padding: 0px 28px;
                        .horizontalBorder {
                            height: 2px;
                        }
                    }
                }

                .verticalBorder {
                    height: 110px;
                    width: 2px;
                    margin-top: 28px;
                }
            }
        }
    }
}

@media screen and (min-width: 450px) and (max-width: $tablet-size-min) {
    .menuScreen {
        .table {
            .row {
                .item {


                    .text {
                        height: 80px;

                        .big {
                            font-size: 34px;
                        }

                        .small {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 550px) and (max-width: $tablet-size-min) {
    .menuScreen {
        .table {
            .row {
                .item {


                    .text {
                        height: 80px;

                        .big {
                            font-size: 43px;
                        }

                        .small {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .menuScreen {
        .headerText {
            h3 {
                margin-top: 19px;
                margin-bottom: 20px;
            }
        }

        .table {
            .row {
                .item {
                    padding: 10px;

                    .text {
                        height: 80px;

                        .big {
                            font-size: 20px;
                        }

                        .small {
                            font-size: 15px;
                        }

                    }

                    &Border {
                        padding: 0px 28px;
                        .horizontalBorder {
                            height: 1px;
                        }
                    }

                    @media (hover: none) and (pointer: coarse) {
                        transition: all .2s ease-in-out;
                        &:active {
                            transform: scale(1.1);
                            transition: .1s;
                        }
                    }
                }

                .verticalBorder {
                    height: 50px;
                    width: 1px;
                    margin-top: 28px;
                }
            }
        }
    }
}