@import "../assets/styles/variables";

.codeInput {
    width: 100%;
    .input1 {
        width: 23%;
        margin-right: 2%;
        display: inline-block;
    }
    .input2 {
        width: 49%;
        margin-right: 2%;
        display: inline-block;
    }
    .input3 {
        width: 11%;
        margin-right: 2%;
        display: inline-block;
    }
    .input4 {
        width: 11%;
        display: inline-block;
    }
}

@media screen and (max-width: $phone-size-max) {
    .codeInput {
        width: 100%;
        .input1 {
            width: 23%;
            margin-right: 2%;
        }
        .input2 {
            width: 35%;
            margin-right: 2%;
        }
        .input3 {
            width: 18%;
            margin-right: 2%;
        }
        .input4 {
            width: 18%;
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .codeInput {
        input {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 19px;
        }
    }
}