@import "../assets/styles/variables";

.locationScreen {
    padding-top: 5px;

    .topLogo {
        padding-top: 35px;
        padding-bottom: 11px;
    }

    .topText {

        h3 {
            margin-top: 0;
            margin-bottom: 25px;
        }
        p {
            margin-top: 25px;
            margin-bottom: 21px;
        }
    }

    .bottomMenu {
        width: 100%;
        margin-top: 48px;
        .text {
            width: 65%;
            display: inline-block;
            margin-right: 5%;
            vertical-align: top;

            h3 {
                margin: 22px 5px;
            }
        }
        .icon {
            width: 15%;
            display: inline-block;

            &.right {
                text-align: right;
            }
        }
    }

    .hintText {
        margin-top: 52px;
        margin-bottom: 18px;
        width: 100%;
        color: #ffffff;
        font-size: $tablet-small-font-size;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.5px;
        line-height: normal;
    }

    .bottomText {
        margin-top: 30px;
        margin-bottom: 12px;
    }

    .mapContainer {
        height: 410px;
        .map {
            width: 100%;
            height: 410px;
        }
    }

    .inputs {
        .row {
            margin-bottom: 14px;

            .postal {
                width: 27%;
                margin-right: 3%;
            }
            .ville {
                width: 70%;
            }
        }
    }

    .locationButtons {
        margin-top: 43px;
        text-align: center;
        max-width: 440px;
        margin-left: auto;
        margin-right: auto;

        .locationButton1 {
            float: left;
            margin-bottom: 20px;
            display: inline-block;
        }
        .locationButton2 {
            float: right;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .locationScreen {
        padding-top: 5px;

        .bottomMenu {
            .text {
                width: 55%;
                h3 {
                    font-size: 15px;
                    margin: 11px;
                }
            }
            .icon {
                width: 20%;


                &.right {

                }
            }
        }

        .hintText {
            margin-top: 20px;
            margin-bottom: 18px;
            font-size: $phone-small-font-size;
        }

        .mapContainer {
            height: 320px;
            .map {
                height: 320px;
            }
        }

        .inputs {
            .row {
                margin-bottom: 14px;

                .postal {

                }
                .ville {

                }
            }
        }

        .locationButtons {
            margin-top: 20px;
        }
    }
    padding-bottom: 20px;
}

@media screen and (max-width: 422px) {
    .locationScreen {
        .bottomMenu {
            .text {
                h3 {
                    //margin-left: 0;
                    //margin-right: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .locationScreen {
        .bottomMenu {
            .text {
                width: 55%;
                h3 {
                    margin: 5px;
                }
            }
            .icon {
                width: 20%;
            }
        }
    }
}