@import "../assets/styles/variables";

.iconButton {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid $border-color;
    background-color: transparent;
    text-decoration: none;
    color: $text-color;
    font-size: 33px;
    line-height: 70px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;

    &.active {
        background-color: #ffffff;
        border-color: #ffffff;
        color: #000000;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #000000;
        }
    }
    @media (hover: none) and (pointer: coarse) {
        transition: all .2s ease-in-out;
        &:active {
            transform: scale(1.1);
            transition: .1s;
        }
    }

    &.small {
        width: 39px;
        height: 39px;
        line-height: 39px;
        font-size: 23px;
    }
    &.middle {
        width: 51px;
        height: 51px;
        line-height: 49px;
        font-size: 26px;
    }
}

@media screen and (max-width: $phone-size-max) {
    .iconButton {
        width: $phone-button-height - 4px;
        height: $phone-button-height - 4px;
        font-size: 27px;
        line-height: 40px;

        &.small {
            width: 33px;
            height: 33px;
            line-height: 34px;
            font-size: 19px;
        }

        &.middle {
            width: 39px;
            height: 39px;
            line-height: 37px;
            font-size: 22px;
        }
    }
}

@media screen and (orientation:landscape) and (max-height: $phone-size-max) {

    .iconButton.middle {
        width: 39px;
        height: 39px;
        line-height: 37px;
        font-size: 22px;
    }
}

@media screen and (max-width: $small-size-max) {
    .iconButton.small {
        width: 22px;
        height: 22px;
        line-height: 24px;
        font-size: 14px;
    }
    .iconButton.middle {
        width: 32px;
        height: 32px;
        line-height: 30px;
        font-size: 18px;
    }
}