@import "../../assets/styles/variables";

.pricesScreen {
   .headerText {
       h3 {
           margin-top: 19px;
           margin-bottom: 100px;
       }
   }
    .headerText2 {
        h3 {
            margin-top: 19px;
            margin-bottom: 50px;
        }
    }

    .table {
        width: 100%;

        .row {
            width: 100%;
            clear: both;
            .item {
                display: table;
                float: left;
                width: 49%;
                text-align: center;
                padding: 28px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .text {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    height: 225px;
                    text-decoration: none;

                    .icon {
                        width: 85px;
                        height: 85px;
                        margin-left: auto;
                        margin-right: auto;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        filter: $invert;
                    }

                    .label {
                        margin-top: 24px;
                        color: $text-color;
                        font-size: 36px;
                        font-weight: 400;
                        text-align: center;
                        font-style: normal;
                        letter-spacing: -0.9px;
                        line-height: normal;
                    }
                }

                &Border {
                    display: table;
                    float: left;
                    width: 49%;
                    text-align: center;
                    padding: 0px 28px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    .horizontalBorder {
                        height: 3px;
                        width: 100%;
                        background-color: $border-color;
                        float: left;
                    }
                }
            }

            .verticalBorder {
                height: 225px;
                width: 3px;
                background-color: $border-color;
                float: left;
                margin-top: 28px;
            }
        }


    }
    .priceList {
        text-align: left;
        width: 100%;
        display: block;

        .block {
            margin-top: 33px;
            margin-bottom: 33px;
            .name {
                .iconWrapper {
                    width: 80px;
                    height: 80px;
                    background-color: #090606;
                    display: inline-block;
                    border-radius: 50%;
                    vertical-align: middle;
                    text-align: center;
                    padding: 15px 0;
                    box-sizing: border-box;
                    filter: $invert;

                    .iconContainer {
                        width: 50px;
                        height: 50px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        display: inline-block;
                    }
                }

                .text {
                    color: $text-color;
                    font-size: 30px;
                    font-weight: 300;
                    font-style: normal;
                    line-height: normal;
                    text-align: left;
                    letter-spacing: -0.75px;
                    display: inline-block;
                    margin-left: 17px;
                    vertical-align: middle;
                }
            }
            .prices {
                margin-top: 8px;

                .item {
                    position: relative;
                    color: $text-color;
                    margin-top: 2px;
                    width: 100%;

                    .itemName {
                       float: left;
                        color: $text-color;
                        font-weight: 300;
                        font-size: 24px;
                        font-style: normal;
                        letter-spacing: -0.6px;
                        line-height: normal;
                        display: block;
                        padding-right: 5px;
                    }

                    .itemPrice {
                        float: right;
                        color: $text-color;
                        font-weight: 300;
                        font-size: 24px;
                        font-style: normal;
                        letter-spacing: -0.6px;
                        line-height: normal;
                        display: block;
                        padding-left: 5px;
                    }
                    .itemDots {
                        font-size: 24px;
                        font-weight: 300;
                        font-style: normal;
                        letter-spacing: -0.35px;
                        line-height: normal;
                        overflow:hidden;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $phone-size-max) {
    .pricesScreen {
        .headerText, .headerText2 {
            h3 {
                margin-top: 19px;
                margin-bottom: 40px;
            }
        }

        .table {
            .row {
                .item {
                    padding: 25px;

                    .text {
                        height: 110px;

                        .icon {
                            width: 85px;
                            height: 85px;
                        }
                        .label {
                            margin-top: 17px;
                            font-size: 25px;
                        }

                    }

                    &Border {
                        padding: 0px 28px;
                        .horizontalBorder {
                            height: 2px;
                        }
                    }
                }

                .verticalBorder {
                    height: 110px;
                    width: 2px;
                    margin-top: 28px;
                }
            }
        }
        .priceList {

            .block {
                margin-top: 25px;
                margin-bottom: 25px;
                .name {
                    .iconWrapper {
                        width: 50px;
                        height: 50px;
                        padding: 10px 0;

                        .iconContainer {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .text {
                        font-size: 24px;
                        margin-left: 17px;
                    }
                }
                .prices {
                    margin-top: 8px;

                    .item {
                        margin-top: 2px;

                        .itemName {
                            font-size: 18px;
                            padding-right: 5px;
                        }

                        .itemPrice {
                            font-size: 18px;
                            padding-left: 5px;
                        }
                        .itemDots {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .pricesScreen {
        .headerText, .headerText2 {
            h3 {
                margin-top: 19px;
                margin-bottom: 20px;
            }
        }

        .table {
            .row {
                .item {
                    padding: 10px;

                    .text {
                        height: 80px;

                        .icon {
                            width: 60px;
                            height: 60px;
                        }
                        .label {
                            margin-top: 12px;
                            font-size: 20px;
                        }

                    }

                    &Border {
                        padding: 0px 28px;
                        .horizontalBorder {
                            height: 1px;
                        }
                    }

                    @media (hover: none) and (pointer: coarse) {
                        transition: all .2s ease-in-out;
                        &:active {
                            transform: scale(1.1);
                            transition: .1s;
                        }
                    }
                }

                .verticalBorder {
                    height: 50px;
                    width: 1px;
                    margin-top: 28px;
                }
            }
        }
    }
}