@import "../assets/styles/variables";

.imageUploader {
    .image {
        width: 259px;
        height: 344px;
        border-radius: 10px;
        background-color: #ffffff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        .button {
            background-color: #000;
            color: #fff;
            border: none;
            width: 45px;
            height: 45px;
            line-height: 45px;
            font-size: 60px;
            font-weight: 300;
            position: absolute;
            bottom: 15px;
            right: 15px;

            &.remove {
                font-size: 34px;
            }

            &:hover {
                background-color: #000;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .imageUploader {
        .image {
            width: 100%;
            height: 270px;

            .button {

                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 60px;
                bottom: 15px;
                right: 15px;

                &.remove {
                    font-size: 34px;
                }

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: $small-size-max) {

}
