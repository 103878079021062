@import "../../../assets/styles/variables";

.subscription {
    padding-bottom: 100px;


    .row1 {
        margin-top: 54px;
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.75px;
        line-height: 48px;
    }

    .codeInput {
        margin-top: 35px;
    }

    .codeButton {
        margin-top: 28px;
    }

    .row2 {
        margin-top: 120px;
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.75px;
    }

    .subscriptionsButton {
        margin-top: 21px;
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (max-width: $phone-size-max) {
    .subscription {
        padding-bottom: 50px;


        .row1 {
            margin-top: 40px;
            font-size: 20px;
            line-height: 30px;
        }

        .codeInput {
            margin-top: 30px;
        }

        .codeButton {
            margin-top: 20px;
        }

        .row2 {
            margin-top: 40px;
            font-size: 20px;
        }

        .subscriptionsButton {
            margin-top: 15px;
        }
    }
}