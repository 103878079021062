@import "../../assets/styles/variables";

.installWelcomeScreen {

    .logo {
        height: 165px;
        margin-top: 95px;
    }

    .row1 {
        margin-top: 450px;
        color: $text-color;
        font-size: 48px;
        font-weight: 200;
        text-align: center;
        font-style: normal;
        letter-spacing: -1.2px;
        line-height: normal;
    }

    .row2 {
        margin-top: 80px;
        padding-bottom: 200px;
        color: $text-color;
        font-size: 44px;
        font-style: italic;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: -1.1px;
        line-height: normal;
    }
}

@media screen and (max-width: $phone-size-max) {
    .installWelcomeScreen {

        .logo {
            height: 60px;
            margin-top: 40px;
        }

        .row1 {
            margin-top: 300px;
            color: $text-color;
            font-size: 30px;
            font-weight: 200;
            text-align: center;
            font-style: normal;
            letter-spacing: -1.2px;
            line-height: normal;
        }

        .row2 {
            margin-top: 100px;
            padding-bottom: 100px;
            color: $text-color;
            font-size: 28px;
            font-style: italic;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: -1.1px;
            line-height: normal;
        }
    }
}