@import "../../assets/styles/variables";

.logo {
  //width: 275px;
  height: 70px;
}
.customLogo {
  height: auto;
  max-height: 150px;
}

@media screen and (max-width: $phone-size-max) {

  .logo {
    //width: 160px;
    height: 41px;
  }

  .customLogo {
    height: auto;
    max-height: 100px;
  }
}

@media screen and (max-width: $small-size-max) {

}