@import "../assets/styles/variables";

.button {
    width: 180px;
    height: 54px;
    display: inline-block;
    border-radius: 10px;
    border: 2px solid $border-color;
    background-color: #000000;
    text-decoration: none;
    color: #ffffff;
    font-size: $tablet-big-font-size;
    line-height: 54px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: -0.75px;
    cursor: pointer;
    padding: 0;

    &.freeWidth {
        width: auto;
        padding-left: 18px;
        padding-right: 18px;
     }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #000000;
        }
    }
    @media (hover: none) and (pointer: coarse) {
        transition: all .2s ease-in-out;
        &:active {
            transform: scale(1.1);
            transition: .1s;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .button {
        width: 130px;
        height: $phone-button-height;
        border: 2px solid #ffffff;
        font-size: $phone-big-font-size;
        line-height: $phone-button-height - 2px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        &.freeWidth {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
}

@media screen and (max-width: $small-size-max) {
    .button {
        width: 109px;
    }
}