@import "../assets/styles/variables";

.notFoundScreen {
    padding-top: 25px;
    .notFoundText {
        margin-top: 98px;
        margin-bottom: 67px;
    }
}

@media screen and (max-width: $phone-size-max) {
    .notFoundScreen {
        .notFoundText {
            margin-top: 60px;
            margin-bottom: 48px;
        }
    }
}