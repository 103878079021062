@import "../../../assets/styles/variables";

.lookScreen {
    .header {
        margin-top: 102px;
        color: #ffffff;
        font-size: $tablet-extra-big-font-size;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        font-style: normal;
        letter-spacing: -0.9px;
        line-height: normal;

        span {
            display: inline-block;
        }
    }
    .subheader {
        color: #000000;
        font-size: $tablet-small-font-size - 2px;
        font-weight: 400;
        text-align: center;
        font-style: normal;
        letter-spacing: -0.45px;
        line-height: normal;
    }
    .settingsList {
        font-size: 0;
        width: 100%;
        display: block;
        text-align: left;
        margin-top: 30px;
        //padding-left: 23px;
        //padding-right: 23px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .item {
            width: 19%;
            display: inline-block;
            margin-bottom: 11px;
            cursor: pointer;
            position: relative;

            .keywordsButton {
                margin-top: 5px;
                background-color: #000000;
                border: none;
                color: #ffffff;
                font-size: 20px;
                font-weight: 300;
                font-style: normal;
                text-align: center;
                letter-spacing: -0.6px;
                height: 50px;
                line-height: 50px;
                width: 100%;
            }

            .image {
                //1582/2048
                width: 100%;
                display: inline-block;
                position: relative;

                > div {
                    width: 100%;
                    height: auto;
                    padding-bottom: 132.8125%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .name {
                color: #ffffff;
                font-size: $tablet-tiny-font-size;
                font-weight: 400;
                text-align: left;
                text-transform: uppercase;
                font-style: normal;
                letter-spacing: -0.35px;
                line-height: normal;
                padding: 7px 10px;

                .lock {
                    color: #ffffff;
                    float: right;
                }
            }
        }

        .divider {
            width: 1%;
            height: 1px;
            display: inline-block;
            &:nth-child(5n) {
                width: 0;
            }
        }
    }
    .pager {
        margin-top: 82px;
        padding-bottom: 60px;

        .pageLink {
            margin-left: 8px;
            margin-right: 8px;
            margin-top: 10px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            background: #666666;
            border: none;

            &:hover {
                color: #fff;
            }
            &.active {
                border: 3px solid #ffffff;
                background-color: #666666;
                line-height: 56px;
            }

            &.disabled {
                background-color: #cccccc;
                color: #999999;
                cursor: default;
            }


        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .lookScreen {
        .header {
            margin-top: 62px;
            font-size: $phone-extra-big-font-size;
        }
        .subheader {
            font-size: $phone-regular-font-size;
        }
        .settingsList {
            .item {
                width: 32%;
                margin-bottom: 6px;

                .name {
                    font-size: $phone-tiny-font-size;
                    padding: 7px 10px;
                }
                .keywordsButton {
                    background-color: #000000;
                    border: none;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 300;
                    font-style: normal;
                    text-align: center;
                    letter-spacing: -0.6px;
                    height: 50px;
                    line-height: 50px;
                    width: 100%;
                }
            }

            .divider {
                width: 2%;
                height: 1px;
                display: inline-block;
                &:nth-child(5n) {
                    width: 2%;
                }
                &:nth-child(3n) {
                    width: 0;
                }
            }

        }
    }
}

@media screen and (max-width: $small-size-max) {
    .lookScreen {

        .settingsList {
            .item {
                width: 48%;

                .name {
                    font-size: $phone-regular-font-size;
                    padding: 7px 10px;
                }
            }

            .divider {
                width: 4%;
                &:nth-child(3n) {
                    width: 4%;
                }
                &:nth-child(5n) {
                    width: 4%;
                }
                &:nth-child(4n) {
                    width: 0;
                }
            }
        }
    }
}