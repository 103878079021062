@import "../assets/styles/variables";

.logoApplication {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.logoApplication img {
    width: 51%;
    /*width: 408px;*/
}

@media screen and (max-width: $phone-size-max) {
    .logoApplication {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .logoApplication img {
        width: 70%;
    }
}