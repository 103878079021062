@import "../../assets/styles/variables";

.installLoginScreen {
    padding-top: 25px;

    .registrationBlock {
        margin-top: 112px;
        padding-bottom: 20px;

        .registrationText {
            color: #ffffff;
            font-size: $tablet-big-font-size;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;

            .firstLine {
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: normal;
            }

            .secondLine {
                font-size: $tablet-regular-font-size;
                font-style: normal;
                letter-spacing: -0.6px;
                line-height: normal;
            }
        }

        .registrationButton {
            text-align: center;
            margin-top: 32px;
        }
    }
}

@media screen and (max-width: $phone-size-max) {
    .installLoginScreen {
        padding-top: 25px;

        .registrationBlock {
            margin-top: 80px;
            .registrationText {
                font-size: $phone-big-font-size;
                .secondLine {
                    font-size: $phone-regular-font-size;
                }
            }
            .registrationButton {
                margin-top: 32px;
            }
        }
    }
}